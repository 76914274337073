import {createStore} from 'redux';
import ls from "local-storage";

function reducer(state = {absoluteDate : 0, dates: {}, authToken : '', isLoading:false, showingPosts : [], cartReserver : [], showNewPost: false, isApiOffline: false, keyword : '', category : 'SVE' }, action)
{
    if (action.type == 'SET_DATES')
    {
        const newState = {...state};
        newState.dates = action.dates;
        if (action.dates.to != "") {
            const timestamp1 = Date.parse(action.dates.to);
            const timestamp2 = Date.parse(action.dates.from);
            const absoluteDate = (timestamp1 - timestamp2) / (1000 * 3600 * 24);
            newState.absoluteDate = absoluteDate + 1;
          }
          else
          {
              const absoluteDate = 0;
              newState.absoluteDate = absoluteDate;
          }
        return newState;
    }
    if (action.type == 'SET_QUERY')
    {
        const newState = {...state};
        newState.category = action.category;
        newState.keyword = action.keyword;
        return newState;
    }
    if (action.type == 'LOADING_SET')
    {
        const newState = {...state};
        newState.isLoading = action.value;
        return newState;
    }
    if (action.type == "API_OFFLINE")
    {
        const newState = {...state};
        newState.isApiOffline = action.value;
        return newState;
    }
    else if (action.type == 'SHOW_NEW_POST')
    {
        const newState = {...state};
        newState.showNewPost = true;
        return newState;
    }
    else if (action.type == 'HIDE_NEW_POST')
    {
        const newState = {...state};
        newState.showNewPost = false;
        return newState;
    }
    else if (action.type == 'ADD_TO_STORE')
    {
        const newPosts = [...state.showingPosts, ...action.posts];
        const newState = {...state};
        newState.showingPosts = newPosts;
        return newState;
    }
    else if (action.type == 'SET_THE_STORE')
    {
        const newState = {...state};
        newState.cartReserver = action.data;
        return newState;
    }
    else if (action.type == 'SET_AUTH_TOKEN')
    {
        const newState = {...state};
        newState.authToken = action.authToken;
        return newState;
    }
    else if (action.type == 'ADD_TO_CART')
    {
        const newState = {...state};
        const newReserver = [...state.cartReserver, action.post];
        newState.cartReserver = newReserver;
        ls.set('store', JSON.stringify(newState.cartReserver));
        return newState;
    }
    else if (action.type == 'REMOVE_FROM_CART')
    {
        const newState = {...state};
        const newCart = [...newState.cartReserver];
        newCart.splice(action.index, 1);
        newState.cartReserver = newCart;
        ls.set('store', JSON.stringify(newState.cartReserver));
        return newState;
    }
    else if (action.type == 'CLEAR_STORE')
    {
        const newState = {...state};
        newState.showingPosts = [];
        console.log('showingPosts');
        console.log(newState.showingPosts);
        return newState;
    }
    else if (action.type == 'CLEAR_CART')
    {
        const newState = {...state};
        newState.cartReserver = [];
        ls.set('store', '');
        return newState;
    }
    else
    {
        return state;
    }
}

const store = createStore(reducer);

export default store;