import "./App.css";

import React, { useEffect, useState } from "react";
import {
  ToastContainer,
  Row,
  Col,
  Carousel,
  Navbar,
  NavDropdown,
  Container,
  Nav,
  Button,
  Image,
  Offcanvas,
  Tabs,
  Tab,
  Form,
  Modal,
  Card,
  Spinner,
  Placeholder,
  FormControl,
  FloatingLabel,
  Toast,
  Alert,
} from "react-bootstrap";

import ReCAPTCHA from "react-google-recaptcha";
import { Redux } from "react-redux";
import { RangePicker } from "react-trip-date";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import carousel1 from "./images/carousel1.png";
import carousel2 from "./images/carousel2.jpg";
import home from "./images/home.png";
import interijer from "./images/interijer.jpg";
import adaptacija1 from "./images/adaptacija2.jpg";
import adaptacija2 from "./images/adaptacija3.jpg";
import rad0 from "./images/rad0.jpg";
import rad1 from "./images/rad1.jpg";
import usluge from "./images/usluge.jpg";
import {
  Route,
  NavLink,
  useHistory,
  Switch,
  useLocation,
} from "react-router-dom";

import ls from "local-storage";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { BsCheckCircleFill } from "react-icons/bs";
import { BsArrowDownCircleFill } from "react-icons/bs";
import { BsCoin } from "react-icons/bs";
import { BsTools } from "react-icons/bs";
import { BsXOctagonFill } from "react-icons/bs";
import store from "./store";
import { useDispatch, useSelector } from "react-redux";
import { handleViewport } from "react-in-viewport";
import CookieConsent from "react-cookie-consent";

const API_ENDPOINT = "https://www.fhc.hr/api";

function HomePageCarousel() {
  return (
    <Carousel
      variant="light"
      indicator={false}
      controls={false}
      fade={true}
      interval={3000}
    >
      <Carousel.Item>
        <div style={{ minWidth: "1200px", width: "100%", height: "500px" }}>
          <img className="d-block w-100" src={carousel1} alt="Second slide" />
          <div className="dark-overlay"></div>
        </div>
        <Carousel.Caption>
          <h5>Od vaše vizije...</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div div style={{ minWidth: "900px", width: "100%", height: "500px" }}>
          <img className="d-block w-100" src={carousel2} alt="Third slide" />
          <div className="dark-overlay"></div>
        </div>
        <Carousel.Caption>
          <h4>...do prekrasnog doma na čvrstim temeljima</h4>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

function Example(props) {
  const handleClose = () => props.setHide();

  return (
    <Offcanvas show={props.show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Kontakt</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <BsFillTelephoneOutboundFill />
        <b> </b>
        <a href="tel:098493395">
          <b>098/493-395</b>
        </a>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function Guide() {
  return (
    <Container className="text-center tutorial">
      <ol style={{ listStyleType: "none" }}>
        <li>Odaberite artikle koje želite unajmiti!</li>
        <BsArrowDownCircleFill />
        <li>Odaberite termine u kalendaru koje želite rezervirati!</li>
        <BsArrowDownCircleFill />
        <li>Neka sustav pošalje upit za Vas!</li>
      </ol>
    </Container>
  );
}
function NotFound() {
  return (
    <div className="page">
      <Container className="text-center">
        <div style={{ height: "200px" }} />
        <h1>404</h1>
        <p>Prazna stranica :)</p>
      </Container>
    </div>
  );
}

function OffcanvasMenu(props) {
  const handleClose = () => props.setHide();
  const [keyword, setKeyword] = useState("");
  const [kategorija, setKategorija] = useState("SVE");
  const history = useHistory();

  function formSubmit(e) {
    setKeyword("");
    setKategorija("SVE");
    e.preventDefault();
    handleClose();
    history.push("/najam");
    props.fetchNew(keyword, kategorija);
  }

  return (
    <Offcanvas show={props.show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Pretraži</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={formSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "left",
              verticalAlign: "text-bottom",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <BsSearch />
            </div>
            <FormControl
              placeholder="Ključna riječ..."
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              aria-label="Username"
              aria-describedby="basic-addon1"
              style={{ display: "inline-block", width: "100%" }}
            />
          </div>
          <h5>Kategorije</h5>
          <FloatingLabel controlId="floatingSelect" label="Kategorija">
            <Form.Select
              value={kategorija}
              onChange={(e) => {
                setKategorija(e.target.value);
              }}
            >
              <option value="Sve">SVE</option>
              <option value="Gletanje">Gletanje</option>
              <option value="Bojanje">Bojanje</option>
              <option value="Infrastruktura">Infrastruktura</option>
              <option value="Vozila">Vozila</option>
              <option value="Radnici">Radnici</option>
            </Form.Select>
          </FloatingLabel>
          <div className="right">
            <Button
              variant="primary"
              type="submit"
              className="searchButton overridenButton"
            >
              Pretraži
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col>
            <p>FHC d.o.o</p>
            <p>Butkovec 54b</p>
            <p>42225 Breznički Hum</p>
            <p>Varaždinska Županija</p>
            <p>© {new Date().getFullYear()}</p>
          </Col>
          <Col>
            <h4>Pratite nas na društvenim mrežama:</h4>
            <hr />
            <a href="https://www.facebook.com/FutureHouseConstruction/">
              <BsFacebook fontSize="40px" className="facebook" />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function Modall(props) {
  const show = props.show;

  const setShow = props.setShow;
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upit poslan</Modal.Title>
      </Modal.Header>
      <Modal.Body>Upit je uspješno poslan!</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Contact() {
  const [showModal, setShowModal] = useState(false);

  function contactHandler(e) {
    e.preventDefault();
    setShowModal(true);
  }

  function hideModal(x) {
    setShowModal(false);
  }

  const [email, setEmail] = useState("");
  const [broj, setBroj] = useState("");
  const [komentar, setKomentar] = useState("");
  const [status, setStatus] = useState(0);
  const [captchaSolved, setCaptchaSolved] = useState(false);

  const recaptchaRef = React.createRef();

  async function sendEmail(e) {
    e.preventDefault();
    recaptchaRef.current.reset();
    setCaptchaSolved(false);
    try {
      setStatus(1);
      const data = await fetch(API_ENDPOINT + "/email/Upit-na-stranici", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }, // or 'PUT'
        body: JSON.stringify({
          data: {
            ime: "UPIT NA STRANICI",
            email: email,
            broj: broj,
            komentar: komentar,
          },
        }),
      });
      if (data.status != 200) {
        setStatus(2);
        return;
      }
      setStatus(3);
    } catch (error) {
      setStatus(2);
    }
  }

  function handleCaptcha(value) {
    setCaptchaSolved(true);
  }

  return (
    <div className="page">
      {" "}
      <div className="imgHolder">
        <Modall show={showModal} setShow={hideModal} />
        <Image src={carousel1} width="150%" />
      </div>
      <div className="daImage"></div>
      <div className="whiteBackgroundContainer">
        <Container>
          <div className="daImage2" />
          <h2>
            Kontaktirajte nas za sve upite, šaljemo odgovor ubrzo nakon upita!
          </h2>
          <Form onSubmit={sendEmail}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email adresa</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Unesite vaš email..."
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicNumber">
              <Form.Label>Kontakt broj</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Unesite vaš broj..."
                value={broj}
                onChange={(event) => {
                  setBroj(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Komentar</Form.Label>
              <Form.Control
                required
                type="textarea"
                placeholder="Upit"
                className="comment"
                value={komentar}
                onChange={(event) => {
                  setKomentar(event.target.value);
                }}
              />
            </Form.Group>
            <ReCAPTCHA
              className="mb-3"
              ref={recaptchaRef}
              sitekey="6Le_m4kdAAAAAODVrxVYxtM4a67MUczMrRMy7OMP"
              onChange={handleCaptcha}
            />
            <Button
              className="mb-3"
              variant="primary"
              type="submit"
              disabled={status == 1 || !captchaSolved}
            >
              Pošalji
            </Button>
            {status == 2 && (
              <Alert variant="warning">Nešto je pošlo po krivu!</Alert>
            )}
            {status == 3 && (
              <Alert variant="success">Mail uspješno poslan!</Alert>
            )}
          </Form>
        </Container>
        <Footer />
      </div>
    </div>
  );
}

function BrandLogo() {
  const history = useHistory();
  function handleClick(e) {
    history.push("/");
  }

  return (
    <Navbar.Brand onClick={handleClick}>
      <i className="hoverIcon">FHC</i>
    </Navbar.Brand>
  );
}

function Navbarr() {
  const history = useHistory();

  function goToStore(e) {
    e.preventDefault();
    history.push("/najam");
  }
  return (
    <React.Fragment>
      <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <BrandLogo />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink
                to="/AboutUs"
                className="navLink"
                activeClassName="activeNavLink"
              >
                O Nama
              </NavLink>
              <NavLink
                to="/Services"
                className="navLink"
                activeClassName="activeNavLink"
              >
                Usluge
              </NavLink>
              <NavLink
                to="/Contact"
                className="navLink"
                activeClassName="activeNavLink"
              >
                Kontakt
              </NavLink>
            </Nav>
            <Nav>
              <Nav.Item>
                <Button onClick={goToStore}>Najam Alata</Button>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ height: "50px" }}></div>
    </React.Fragment>
  );
}

function AddNewPost(props) {
  const [adName, setAdName] = useState("");
  const [adDescription, setAdDescription] = useState("");
  const [priceDay, setPriceDay] = useState(0);
  const [tags, setTags] = useState("");
  const [category, setCategory] = useState("SVE");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [adLong, setAdLong] = useState("");

  const [loading, setLoading] = useState(0);
  const authToken = useSelector((store) => {
    return store.authToken;
  });

  async function submit(event) {
    event.preventDefault();
    setLoading(1);

    const formData = new FormData();

    formData.append("adName", adName);
    formData.append("adDescription", adDescription);
    formData.append("adLong", adLong);
    formData.append("priceDay", priceDay);
    formData.append("tags", tags);
    formData.append("category", category);
    const images = [];
    for (let i = 0; i < selectedFiles.length; ++i) {
      formData.append("images", selectedFiles[i], selectedFiles[i].name);
    }
    formData.append("authToken", authToken);
    try {
      const res = await fetch(API_ENDPOINT + "/posts", {
        method: "POST", // or 'PUT'
        body: formData,
      });
      if (res.status == "200") {
        setLoading(2);
      } else {
        setLoading(3);
      }
    } catch (error) {
      setLoading(3);
    }
  }

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <h2>Dodaj novi oglas</h2>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submit}>
          {(loading == 2 || loading == 3) && (
            <Toast>
              <Toast.Header>
                <strong className="me-auto">Upload</strong>
                <small>Just now</small>
              </Toast.Header>
              <Toast.Body>
                {loading == 2
                  ? "Oglas uspješno postavljen"
                  : "Nešto je pošlo po krivu"}
              </Toast.Body>
            </Toast>
          )}
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Slike</Form.Label>
            <FormControl
              id="formControlsFile"
              type="file"
              multiple
              label="Slike"
              files={selectedFiles}
              onChange={(event) => {
                setSelectedFiles(event.target.files);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Ime Oglasa</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              onChange={(event) => {
                setAdName(event.target.value);
              }}
              value={adName}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Opis oglasa</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={adDescription}
              onChange={(event) => {
                setAdDescription(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Dugački opis</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={adLong}
              onChange={(event) => {
                setAdLong(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Cijena po danu (HRK)</Form.Label>
            <Form.Control
              type="number"
              rows={1}
              value={priceDay}
              onChange={(event) => {
                setPriceDay(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Tagovi(odvojeni dvotockom)</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={tags}
              onChange={(event) => {
                setTags(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <FloatingLabel controlId="floatingSelect" label="Kategorija">
              <Form.Select
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <option value="Sve">SVE</option>
                <option value="Gletanje">Gletanje</option>
                <option value="Bojanje">Bojanje</option>
                <option value="Infrastruktura">Infrastruktura</option>
                <option value="Vozila">Vozila</option>
                <option value="Radnici">Radnici</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={loading == 1 ? true : false}
          >
            Pošalji
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function Services(props) {
  return (
    <div className="page">
      <div className="imgHolder">
        <Image src={usluge} width="150%" />
      </div>
      <div className="daImage"></div>
      <div className="whiteBackgroundContainer">
        <Container className="mainMenuContainer whiteBackgroundContainer">
          <div className="daImage2" />
          <h1>Naše Usluge</h1>
          <hr />
          <Container className="whiteBackgroundContainer">
            <Row className="animatedContainer" xs={1}>
              <Col className="text-center my-auto">
                <h1 className="margined">Izvođenje građevinskih radova</h1>
              </Col>
              <Col>
                <Tabs
                  defaultActiveKey="profile"
                  id="uncontrolled-tab-example"
                  className="mb-3 tabs"
                >
                  <Tab eventKey="home" title="Ključ u ruke">
                    <p>
                      Građenje po sistemu „ključ u ruke“ podrazumijeva izvedbu
                      kompletnih radova na objektu uključujući i one na uređenju
                      okoliša. Ovom uslugom objekt je nakon izgradnje spreman za
                      tehnički pregled i u potpunosti završen i spreman za
                      korištenje. Od ostalih tvrtki na tržištu razlikujemo se po
                      tome što nudimo uslugu „od projekta do objekta“, odnosno
                      prisutni smo kod cijelog procesa projektiranja i gradnje.
                    </p>
                  </Tab>
                  <Tab eventKey="profile" title="Roch-Bau">
                    <p>
                      Roh-Bau je naziv za sve radove koje izvođači izvode
                      polovično. Dakle izvođači će izgraditi sve, a vi sami
                      nalazite majstora za parkete, pločice, vodoinstalatera,
                      električara i tako dalje. Sama riječ dolazi od njemačkih
                      riječi “roh” što znači “sirov” te “bau” što znači gradnja.
                      Dakle, sirova gradnja. Postoje tri stupnja roh-bau
                      gradnje; niski, srednji i visoki. Izgradnja kuće veliki je
                      korak za svakog pojedinca, ali i investitora, bilo da je
                      kuća, bilo objekt druge namjene. Izgradnji pristupamo
                      maksimalno ozbiljno i odgovore na sva pitanja, vezana za
                      objekt koji će se graditi, zajedno ćemo definirati u
                      prethodnim razgovorima.
                    </p>
                  </Tab>
                  <Tab eventKey="contact" title="Pojedinačni radovi">
                    <p>
                      Vršimo i sve radove koje ne ulaze u prethodna dva
                      najpopularnija tipova gradnje. Sve od temelja pa do krova.
                    </p>
                  </Tab>
                </Tabs>
              </Col>
            </Row>

            <hr />
            <Row className="animatedContainer" xs={1}>
              <Col className="text-center my-auto">
                <h1 className="margined">Projektantske usluge</h1>
              </Col>
              <Col>
                <Tabs
                  defaultActiveKey="profile"
                  id="uncontrolled-tab-example"
                  className="mb-3 tabs"
                >
                  <Tab eventKey="home" title="Projektna dokumentacija">
                    <p>
                      Izrada projektne dokumentacije za cijelokupni životni
                      ciklus projekta, od ideje nadalje.
                    </p>
                  </Tab>
                  <Tab eventKey="profile" title="Izrada troškovnika">
                    <p>
                      Ako se spremate u gradnju ili adaptaciju Vašeg prostora, a
                      niste sigurni od kuda krenuti, onda je ovo pravo mjesto za
                      Vas. Za svaku adaptaciju ili gradnju objekata nužno je
                      napraviti troškovnik svih potrebnih radova, kako bi mogli
                      planirati Vaše troškove. Također ako dižete kredit u banci
                      će Vas tražiti taj dokument. Nudimo Vam izradu troškovnika
                      za sve Vaše potrebe adaptacije ili gradnje.
                    </p>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </Container>

        <Footer />
      </div>
    </div>
  );
}

function ImgModal(props) {
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Image src={props.src} thumbnail fluid />
      </Modal.Body>
    </Modal>
  );
}

function NoResults() {
  return (
    <Container className="text-center no-more-articles">
      <BsCheckCircleFill fontSize="70" />
      <h2>Nema više artikala</h2>
    </Container>
  );
}

function LogIn() {
  const [failedLogIn, setFailed] = useState(false);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  async function loginHandler(e) {
    e.preventDefault();
    try {
      const data = await fetch(API_ENDPOINT + "/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }, // or 'PUT'
        body: JSON.stringify({ email: email, password: password }),
      });
      const json = await data.json();
      const authToken = json.AuthToken;
      if (authToken == "no") {
        setFailed(true);
        return;
      }
      dispatch({ type: "SET_AUTH_TOKEN", authToken: authToken });
      setFailed(false);
    } catch (error) {
      setFailed(true);
    }
  }

  return (
    <Form onSubmit={loginHandler}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email adresa</Form.Label>
        <Form.Control
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          type="email"
          placeholder="Unesite vaš email..."
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicNumber">
        <Form.Label>Lozinka</Form.Label>
        <Form.Control
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          type="password"
          placeholder=""
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Pošalji
      </Button>
      {failedLogIn && <p>Neuspjela prijava!</p>}
    </Form>
  );
}

function StoreNavbar(props) {
  const [authed, alatnica] = useSelector((state) => [
    state.authToken,
    state.cartReserver,
  ]);
  const authTokenExists = authed != null && authed.length > 0;

  const [sideMenu, showSideMenu] = useState(false);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <h3>
            <i>FHC NAJAM ALATA</i>
          </h3>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {authTokenExists && (
                <Nav.Item
                  onClick={() => {
                    dispatch({ type: "SHOW_NEW_POST" });
                  }}
                >
                  <Button variant="secondary" className="spacedButton">
                    Novi oglas
                  </Button>
                </Nav.Item>
              )}
            </Nav>
            <Nav className="ml-auto sideButtons">
              <Nav.Item
                onClick={() => {
                  showSideMenu(true);
                }}
              >
                <div className="main-link">
                  <BsSearch fontSize="30" />
                  <p>Pretraži</p>
                </div>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/alatnica" activeClassName="orangeNav">
                  <div className="main-link">
                    <BsTools fontSize="30" />
                    <p>Alatnica</p>
                  </div>
                </NavLink>

                <span className="alatnicaNum">{alatnica.length}</span>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={{ height: "50px" }}></div>
      <OffcanvasMenu
        show={sideMenu}
        setHide={() => {
          showSideMenu(false);
        }}
        fetchNew={props.fetchNew}
      ></OffcanvasMenu>
    </React.Fragment>
  );
}

function AdvertOpen(props) {
  const dispatch = useDispatch();
  const [deleteStatus, setDeleteStatus] = useState(0);
  const authToken = useSelector((state) => {
    return state.authToken;
  });
  async function deletePost() {
    try {
      setDeleteStatus(1);
      const data = await fetch(API_ENDPOINT + "/posts/" + props.item._id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }, // or 'PUT'
        body: JSON.stringify({ authToken: authToken, img: props.item.img }),
      });
      if (data.error != null) {
        setDeleteStatus(3);
        return;
      }
      props.fetchNew("", "SVE");
      setDeleteStatus(2);
    } catch (error) {
      setDeleteStatus(3);
    }
  }

  const imageSrcs = [];

  if (props.item == null) {
    return <React.Fragment />;
  }
  const imgs = props.item.img;

  for (const img of imgs) {
    imageSrcs.push(API_ENDPOINT + "/images/" + img);
  }

  const elements = [];
  let counter = 0;
  if (imageSrcs.length == 0) {
    elements.push(
      <Carousel.Item key={counter}>
        <img
          className="d-block w-100"
          src={API_ENDPOINT + "/images/undefined"}
          alt="slide"
        />
      </Carousel.Item>
    );
  }
  for (let imgSrc of imageSrcs) {
    elements.push(
      <Carousel.Item key={counter}>
        <img className="d-block w-100" src={imgSrc} alt="slide" />
      </Carousel.Item>
    );
    ++counter;
  }

  let variant = "";
  let displayText = "";
  if (deleteStatus == 3) {
    variant = "danger";
    displayText = "Nije uspjelo brisanje!";
  } else if (deleteStatus == 2) {
    variant = "success";
    displayText = "Uspješno obrisano!";
  } else if (deleteStatus == 1) {
    variant = "primary";
    displayText = "Brišem iz baze...";
  }
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        props.onHide();
        setDeleteStatus(0);
      }}
    >
      <Modal.Header closeButton>
        <h2>{props.item.title}</h2>
      </Modal.Header>
      <Modal.Body>
        <Carousel
          variant="light"
          indicator={false}
          controls={false}
          fade={true}
          interval={3000}
        >
          {elements}
        </Carousel>
        <hr />
        <p>{props.item.adLong}</p>
        <hr />
        <i className="priceIndicator">{props.item.price + " HRK / Dan"}</i>
        <BsCoin className="priceIndicator" />
        {deleteStatus != 2 && (
          <Button
            variant="primary"
            className="overridenButton"
            onClick={() => {
              dispatch({ type: "ADD_TO_CART", post: props.item });
              props.toastFunction();
              setDeleteStatus(0);
              props.onHide();
            }}
          >
            Dodaj u Alatnicu
          </Button>
        )}
        {authToken.length > 0 && (
          <Button
            variant="primary"
            onClick={() => {
              deletePost();
            }}
          >
            Obriši post
          </Button>
        )}

        {authToken.length > 0 && deleteStatus != 0 && (
          <Alert variant={variant}>{displayText}</Alert>
        )}
      </Modal.Body>
    </Modal>
  );
}

function AlatnicaCard(props) {
  const dispatch = useDispatch();

  return (
    <Container className="alatnicaCard">
      <Row>
        <span className="text-end">
          <BsXOctagonFill
            className="titusCross"
            onClick={() => {
              dispatch({ type: "REMOVE_FROM_CART", index: props.index });
            }}
          />
        </span>
        <h5>{props.item.title}</h5>
      </Row>
      <p>{props.item.content}</p>
      <hr />
      <p className="text-end">{props.item.price} HRK/dan</p>
    </Container>
  );
}

function ToolShed() {
  const [totalPrice, alatnica, dates] = useSelector((state) => [
    state.absoluteDate,
    state.cartReserver,
    state.dates,
  ]);

  const alatnicaList = [];
  const [modalDisplay, setShowModalDisplay] = useState(false);

  const dispatch = useDispatch();

  let ukupno = 0;
  for (let i = 0; i < alatnica.length; ++i) {
    ukupno += Number(alatnica[i].price);
    alatnicaList.push(
      <AlatnicaCard item={alatnica[i]} key={alatnica[i]._id} index={i} />
    );
  }

  const theme = {
    primary: {
      light: "#FF7900",
      main: "#FF7900",
      dark: "#FF7900",
    },
    background: {
      default: "white",
    },
    text: {},
  };

  function handleDays(days) {
    if (days.to != null) {
      dispatch({ type: "SET_DATES", dates: days });
    } else {
      dispatch({ type: "SET_DATES", dates: {} });
    }
  }

  return (
    <Container className="mainStoreContainer">
      <SubmitStoreForm
        show={modalDisplay}
        onHide={() => {
          setShowModalDisplay(false);
        }}
      />
      <Row xs={1} md={2}>
        <Col className="fixedHeightCol">
          <Container xs={7} className="fixedHeightContainer section">
            {alatnicaList.length
              ? alatnicaList
              : "Trenutačno nemate ništa u alatnici..."}
          </Container>
          <hr />
          <h5>Ukupno po danu: {`${ukupno} HRK/dan`}</h5>
        </Col>

        <Container>
          <h3 className="calendarHeader">Odaberite razdoblje za najam:</h3>
          <hr />
          <RangePicker
            disabledBeforeToday={true}
            startOfWeek={1}
            theme={theme}
            onChange={(days) => {
              handleDays(days);
            }}
            selectedDays={
              dates != null && dates.to != null ? dates : { from: "", to: "" }
            }
          />
        </Container>
      </Row>
      <hr />
      {totalPrice > 0 && ukupno > 0 && (
        <h2>Ukupno: {totalPrice * ukupno} HRK</h2>
      )}
      <Button
        type="primary"
        onClick={() => {
          setShowModalDisplay(true);
        }}
        disabled={
          alatnicaList.length != 0 &&
          dates != null &&
          dates.to != "" &&
          dates.to != null
            ? false
            : true
        }
        className="overridenButton najamButton"
      >
        Pošalji upit
      </Button>
    </Container>
  );
}

function SubmitStoreForm(props) {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [komentar, setKomentar] = useState("");
  const [ime, setIme] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const [cart, dates] = useSelector((state) => [
    state.cartReserver,
    state.dates,
  ]);
  const [failed, setFailed] = useState(0);

  async function handleSubmit(e) {
    dispatch({ type: "CLEAR_CART" });
    e.preventDefault();
    try {
      setFailed(1);
      const data = await fetch(API_ENDPOINT + "/email/Najam-Upit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }, // or 'PUT'
        body: JSON.stringify({
          data: {
            ime: ime,
            email: email,
            phone: phone,
            komentar: komentar,
            cart: cart,
            datumi: dates,
          },
        }),
      });
      if (data.status != 200) {
        setFailed(2);
      } else {
        setFailed(0);
        props.onHide();
        history.push("/thanks");
      }
    } catch (error) {
      setFailed(2);
    }
  }

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <h2>Pošalji upit</h2>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Vaše ime:</Form.Label>
            <Form.Control
              required
              as="input"
              type="text"
              rows={2}
              onChange={(event) => {
                setIme(event.target.value);
              }}
              value={ime}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Vaš e-mail:</Form.Label>
            <Form.Control
              required
              as="input"
              type="email"
              rows={2}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Vaš komentar (neobavezno):</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={komentar}
              onChange={(event) => {
                setKomentar(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Vaš broj telefona/mobitela (neobavezno):</Form.Label>
            <Form.Control
              type="number"
              rows={1}
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
          </Form.Group>
          <Button
            disabled={failed == 1}
            variant="primary"
            type="submit"
            className="overridenButton"
          >
            Pošalji Zahtjev!
          </Button>
        </Form>

        {failed == 2 && (
          <Alert variant="warning">
            Nešto je pošlo po krivu, pokušajte opet!
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  );
}

function StorePage() {
  const [showPost, available, apiOffline, isLoading, keyword, category] =
    useSelector((state) => {
      return [
        state.showNewPost,
        state.showingPosts,
        state.isApiOffline,
        state.isLoading,
        state.keyword,
        state.category,
      ];
    });
  const location = useLocation();
  const [isLastEntry, setLastEntry] = useState(false);
  const [item, setItem] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [lastAdded, setLastAdded] = useState("");
  const dispatch = useDispatch();
  function onHideShowPost() {
    dispatch({ type: "HIDE_NEW_POST" });
  }

  async function fetchNew(keywords, category) {
    try {
      const available = [];
      if (isLoading) return;
      setLastEntry(false);
      await dispatch({ type: "LOADING_SET", value: true });
      await dispatch({ type: "CLEAR_STORE" });
      await dispatch({
        type: "SET_QUERY",
        category: category,
        keyword: keywords,
      });

      let keywordPost = "";
      let categoryPost = "";

      if (keywords.length > 0) {
        keywordPost = "?keywords=" + keywords;
      }
      if (category.length > 0 && keywords.length > 0) {
        categoryPost = "&category=" + category;
      } else {
        categoryPost = "?category=" + category;
      }
      const data = await fetch(
        API_ENDPOINT + ("/posts" + keywordPost + categoryPost)
      );
      const response = await data.json();
      if (response.length < 12) {
        setLastEntry(true);
      }
      if (response.length == 0) {
        await dispatch({ type: "LOADING_SET", value: false });
        return;
      }
      await dispatch({ type: "ADD_TO_STORE", posts: response });
      await dispatch({ type: "LOADING_SET", value: false });
    } catch (error) {
      await dispatch({ type: "API_OFFLINE", value: true });
      await dispatch({ type: "LOADING_SET", value: false });
    }
  }

  async function fetchPostsFunction() {
    try {
      if (isLoading) return;
      setLastEntry(false);
      dispatch({ type: "LOADING_SET", value: true });
      let keywordPost = "";
      let categoryPost = "SVE";
      if (keyword.length > 0 && available.length == 0) {
        keywordPost = "?keywords=" + keyword;
      } else if (keyword.length > 0) {
        keywordPost = "&keywords=" + keyword;
      }
      if (category.length > 0 && (keyword.length > 0 || available.length > 0)) {
        categoryPost = "&category=" + category;
      } else {
        categoryPost = "?category=" + category;
      }
      const data = await fetch(
        API_ENDPOINT +
          (available.length != 0
            ? "/posts" +
              `?timestamp=${available[available.length - 1].timestamp}` +
              keywordPost +
              categoryPost
            : "/posts" + keywordPost + categoryPost)
      );
      const response = await data.json();
      if (response.length < 12) {
        setLastEntry(true);
      }
      if (response.length == 0) {
        dispatch({ type: "LOADING_SET", value: false });
        return;
      }
      dispatch({ type: "ADD_TO_STORE", posts: response });
      dispatch({ type: "LOADING_SET", value: false });
    } catch (error) {
      dispatch({ type: "API_OFFLINE", value: true });
      dispatch({ type: "LOADING_SET", value: false });
    }
  }

  function initialFunction() {
    try {
      const store = ls.get("store");
      if (store && store.length > 0) {
        const data = JSON.parse(store);
        dispatch({ type: "SET_THE_STORE", data: data });
      }
    } catch (e) {}
  }

  useEffect(initialFunction, []);
  useEffect(fetchPostsFunction, []);

  const cards = [];

  for (let i = 0; i < available.length; ++i) {
    cards.push(
      <DisplayCard
        item={available[i]}
        key={i}
        onClick={() => {
          setItem(available[i]);
        }}
      />
    );
  }

  const FirstLoadingCard = handleViewport(LoadingCard);
  if (apiOffline) {
    return (
      <div className="background-store">
        <Container className="text-center">
          <h1>FHC NAJAM ALATA</h1>
          <h2>Trenutačno imamo problema sa serverom...</h2>
        </Container>
      </div>
    );
  }
  return (
    <div className="background-store">
      <AdvertOpen
        fetchNew={fetchNew}
        show={item != null}
        item={item}
        onHide={() => {
          setItem(null);
        }}
        toastFunction={() => {
          setShowToast(true);
          setLastAdded(item.title);
        }}
      />
      <AddNewPost show={showPost} onHide={onHideShowPost} />
      <StoreNavbar fetchNew={fetchNew} />
      <TransitionGroup>
        <CSSTransition
          timeout={500}
          classNames="page2"
          unmountOnExit
          key={location.pathname}
          appear={true}
        >
          <Switch location={location}>
            <Route path="/thanks" exact>
              <div className="background-store">
                <Container className="thanksContainer">
                  <h2>Hvala na zahtjevu!</h2>
                  <hr />
                  <p>Javiti ćemo Vam se ubrzo!</p>
                </Container>
              </div>
            </Route>
            <Route path="/alatnica" exact>
              <div className="page2">
                <ToolShed />
                <Footer />
              </div>
            </Route>
            <Route path="/login" exact>
              <LogIn />
            </Route>
            <Route path="/najam" exact>
              <div className="page2">
                <Container className="searchDisplay">
                  <h2>{`Prikazujem ${keyword} u kategoriji:${category}`}</h2>
                </Container>
                <Container className="mainStoreContainer">
                  <Row lg={3}>
                    {cards}
                    {(!isLastEntry || isLoading) && (
                      <React.Fragment>
                        <div>
                          <FirstLoadingCard
                            onEnterViewport={fetchPostsFunction}
                          />
                        </div>
                      </React.Fragment>
                    )}
                    {isLastEntry && <NoResults />}
                  </Row>
                </Container>
                <Footer />
                <div className="viewPort">
                  <ToastContainer className="p-3" position={"bottom-end"}>
                    <Toast
                      show={showToast}
                      onClose={() => {
                        setShowToast(false);
                      }}
                    >
                      <Toast.Header closeButton={true}>
                        <strong className="me-auto">Alatnica</strong>
                      </Toast.Header>
                      <Toast.Body>
                        <b>{lastAdded}</b> dodan u alatnicu!
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                </div>
              </div>
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

function DisplayCard(props) {
  return (
    <div>
      <Card className="defaultCard" onClick={props.onClick}>
        <Container className="loadingCardImageContainer">
          <img
            className="containedImage"
            src={API_ENDPOINT + "/images/" + props.item.img[0]}
          />
        </Container>
        <Card.Body>
          <Card.Title>{props.item.title}</Card.Title>
          <Card.Text>{props.item.content}</Card.Text>
          <Card.Text>
            <i className="priceIndicator">{props.item.price + " HRK / Dan"}</i>
            <BsCoin />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

function LoadingCard(props) {
  return (
    <Card className="defaultCard" ref={props.forwardedRef}>
      <Container className="loadingCardImageContainer">
        <Spinner
          className="centerSpinner"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      </Container>
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{" "}
          <Placeholder xs={6} /> <Placeholder xs={8} />
        </Placeholder>
      </Card.Body>
    </Card>
  );
}

function App() {
  const [show, setShow] = useState(false);
  const [launched, setLaunched] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [img, setImg] = useState(null);
  const location = useLocation();

  function openImage(src) {
    setImg(src);
    setShowImg(true);
  }

  function closeImage() {
    setShowImg(false);
  }

  function setShowTrue() {
    setShow(true);
  }

  function setShowFalse() {
    setShow(false);
  }

  useEffect(() => {
    setLaunched(true);
  }, []);
  return (
    <React.Fragment>
      <Switch>
        <Route path="/thanks">
          <StorePage />
        </Route>
        <Route path="/najam">
          <StorePage />
        </Route>

        <Route path="/alatnica">
          <StorePage />
        </Route>

        <Route path="/login">
          <StorePage />
        </Route>
        <Route path="/">
          <div>
            <Example show={show} setHide={setShowFalse} />
            <Navbarr />
            <TransitionGroup>
              <CSSTransition
                timeout={500}
                classNames="page"
                unmountOnExit
                key={location.pathname}
                appear={true}
              >
                <Switch location={location}>
                  <Route path="/" key="/" exact>
                    <div className="page">
                      <HomePageCarousel />
                      <ImgModal src={img} onHide={closeImage} show={showImg} />
                      <Container className="mainMenuContainer">
                        <Row sm={1} xs={1} md={2} fluid>
                          <Col>
                            <h1 className="paddedH1">
                              Želite li izgraditi kuću, stambeni objekt ili pak
                              ostvariti unutarnju ili vanjsku adaptaciju?
                            </h1>
                            <p>
                              Kroz zadnjih desetak godina smo pokazali da smo
                              sposobni za sve što se tiče građevinskih radova.
                              Ulagali smo, i nastojimo uvijek kroz vrijeme
                              ulagati u najnovija rješenja stambenih izvođenja.
                            </p>
                            <p>
                              Ulaganjem u najnovije alate ostvarujemo vidno
                              bolje rezultate, te strojnim izvođenjem većine
                              radova ostvarujemo da su svi vidljivi i nevidljivi
                              rezultati blizu savršenstva.
                            </p>
                            <p>
                              Ono što nas dijeli od konkurencije, osim strojnih
                              izvođenja radova, je da u svaki projekt ulazimo s
                              pristupom kao da nam je prvi. Komuniciranjem sa
                              klijentom kao ključnim dijelom procesa izgradnje,
                              nastojimo da klijent uvijek dobije rezultate koji
                              su jako slični njegovoj zamisli.
                            </p>
                            <p>
                              Bilo to savjetovanje oko ideje, ili pretvaranje te
                              ideje u stvarnost, uz klijenta smo u cijelom
                              procesu izvođenja.
                            </p>
                          </Col>
                          <Col>
                            <img src={home} width="90%"></img>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>
                            <h2 className="mainMenuContainer">
                              Od našeg osnutka 2013. godine - vođeni magistrom
                              građevine.
                            </h2>
                            <Container>
                              <Container className="pictures">
                                <Row>
                                  <Col xs={6} md={4}>
                                    <Image
                                      src={interijer}
                                      thumbnail
                                      fluid
                                      onClick={() => {
                                        openImage(interijer);
                                      }}
                                    />
                                  </Col>
                                  <Col xs={6} md={4}>
                                    <Image
                                      src={adaptacija2}
                                      thumbnail
                                      fluid
                                      onClick={() => {
                                        openImage(adaptacija2);
                                      }}
                                    />
                                  </Col>
                                  <Col xs={6} md={4}>
                                    <Image
                                      src={adaptacija1}
                                      thumbnail
                                      fluid
                                      onClick={() => {
                                        openImage(adaptacija1);
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </Container>
                            <p>
                              Tijekom godina smo izgradili na desetke domova te
                              adaptirali još više stanova. U svakoj situaciji
                              nastojimo raditi u tandemu sa klijentom - kao
                              klijentov izvođač, a i savjetnik, nastojimo da
                              klijent uvijek dobije "ključ u ruke" sa smiješkom
                              na njegovom licu. Vi, kao naš klijent, ste u našem
                              timu, te ćemo Vam uvijek nastojati pomoći najviše
                              što možemo, te izgladiti sve šumove u komunikaciji
                              koje bi potencijalno mogle dovesti do
                              nezadovoljsta.
                            </p>
                            <h5>
                              Na temelju <b>VAŠEG</b> zadovoljstva stavljamo
                              kruh na naš stol.
                            </h5>
                          </Col>
                        </Row>
                      </Container>
                      <Container className="mainMenuContainer textCenter">
                        <hr />
                        <h2>
                          Ukoliko bi htjeli Vašu građevinsku zamisao pretvoriti
                          u realnost, nazovite nas za{" "}
                          <b>besplatnu konzultaciju</b>:
                        </h2>
                        <Button onClick={setShowTrue}> Želim nazvati!</Button>
                      </Container>

                      <Footer />
                    </div>
                  </Route>
                  <Route path="/AboutUs" key="/A">
                    <div className="page">
                      <Container className="mainMenuContainer">
                        <h1>O nama</h1>
                        <hr />
                        <h4>Započinjemo radom 2013. godine...</h4>
                        <p>
                          Zapošljavanjem prvih radnika krećemo od rada na
                          adaptacijama stanova i kuća, te kroz godine
                          napredujemo prema većim projektima...
                        </p>
                        <p>
                          Od izvođenja manjih radova, pa sve do izvođenja većih
                          zahvata kao kooperanti radimo prema principu da
                          nastojimo da svaki klijent bude zadovoljan na kraju
                          izvršenja, dok u isto vrijeme nastojimo da svaki naš
                          pothvat ima vizualno posebni karakter.
                        </p>
                        <p>
                          Orijentirani smo kao manja firma koja promovira
                          sinergiju i dobre odnose između zaposlenika.
                          Zapošljavamo ljude neovisno o nacionalnosti i bez
                          predrasuda, bitni su nam samo njihova znanja i
                          iskustvo.
                        </p>

                        <hr />
                        <h4>Vlasnik firme</h4>
                        <p>Vlasnik firme je Robert Franjčić, mag.ing.aedif.</p>
                      </Container>

                      <Footer />
                    </div>
                  </Route>
                  <Route path="/Services" key="/S">
                    <Services />
                  </Route>
                  <Route path="/Contact" key="/C">
                    <Contact />
                  </Route>
                  <Route path="/" key="/default">
                    <NotFound />
                  </Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </Route>
      </Switch>
        <CookieConsent buttonText="Prihvati">
          {" "}
          Ova webstranica koristi minimalni broj pregledničkih kolačića u svrhu
          unaprijeđenja aplikacije.
        </CookieConsent>
    </React.Fragment>
  );
}

export default App;
